/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-var */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import eurekaMgrs from '@eureka/ui-managers';
import { AppState, SetAppState, ConfigJson, Settings, UserRef } from './types';
import { History } from 'history';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(updateLocale);

const children: JSX.Element[] = []; // children component list
const { eventBus } = eurekaMgrs;

export const UtcDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
export const rawSetting = { basicSetup: {}, userProfile: {}, companyProfile: {} };

// returns a dst datetime DayJS object
export const getDstDateTimeFromUtc = (utcDateTimeOrStr: Date | string, settings: Settings) => {
  if (utcDateTimeOrStr instanceof Date) {
    utcDateTimeOrStr = dayjs.tz(utcDateTimeOrStr).format(UtcDateTimeFormat);
  }
  return dayjs.utc(utcDateTimeOrStr).tz(settings.timeZone);
};

// returns a formatted dst datetime string
export const displayDSTFromUTC = (
  utcDateTimeOrStr: Date | string,
  dateFormat: string,
  settings: Settings,
) => {
  const dstDate = getDstDateTimeFromUtc(utcDateTimeOrStr, settings);
  const timeFormat = settings.timeFormat === 'TWENTYFOUR_HOUR' ? 'HH:mm:ss' : 'hh:mm:ss A';
  const modifiedDateTimeFormat = `${settings.dateFormat} ${timeFormat}`;
  return dstDate.format(dateFormat || modifiedDateTimeFormat);
};

// returns a formmatted dst date string by default
// if set `keepLocalTime` to true, local time will be kept. refer to https://day.js.org/docs/en/timezone/converting-to-zone
export const displayDateFromUTC = (
  utcDateTimeOrStr: Date | string,
  settings: Settings,
  keepLocalTime?: boolean,
) => {
  if (utcDateTimeOrStr instanceof Date) {
    utcDateTimeOrStr = dayjs.tz(utcDateTimeOrStr).format(UtcDateTimeFormat);
  }
  if (keepLocalTime) {
    return dayjs.utc(utcDateTimeOrStr).format(settings.dateFormat);
  } else {
    return dayjs.utc(utcDateTimeOrStr).tz(settings.timeZone).format(settings.dateFormat);
  }
  // keepLocalTime is currently not working for Etc/UTC timezone.
  // return dayjs.utc(utcDateTimeOrStr).tz(settings.timeZone, keepLocalTime).format(settings.dateFormat)
};

// returns a utc datetime DayJS object
export const getUtcDateTimeFromDst = (
  dstDateTimeOrStr: Date | string,
  dateFormat: string,
  settings: Settings,
) => {
  const timeFormat = settings.timeFormat === 'TWENTYFOUR_HOUR' ? 'HH:mm:ss' : 'hh:mm:ss A';
  const modifiedDateTimeFormat = `${settings.dateFormat} ${timeFormat}`;
  if (dstDateTimeOrStr instanceof Date) {
    dstDateTimeOrStr = dayjs.tz(dstDateTimeOrStr).format(dateFormat || modifiedDateTimeFormat);
  }
  // console.error(dstDateTimeOrStr, dateFormat || modifiedDateTimeFormat, settings.timeZone)
  return dayjs
    .tz(dstDateTimeOrStr, dateFormat || modifiedDateTimeFormat, settings.timeZone)
    .tz('Etc/GMT');
};

// returns a formatted utc datetime string
export const getUTCFromDST = (
  dstDateTimeOrStr: Date | string,
  dateFormat: string,
  settings: Settings,
  utcFormat: string,
) => {
  return getUtcDateTimeFromDst(dstDateTimeOrStr, dateFormat, settings).format(
    utcFormat || UtcDateTimeFormat,
  );
};

export const mergeSettings = (setting: Settings) => {
  const { basicSetup, userProfile, companyProfile } = setting;
  const settings = { ...basicSetup, ...companyProfile };
  Reflect.deleteProperty(settings, 'id');
  if (userProfile?.userUpdateState && parseFloat(userProfile?.userUpdateState)) {
    Object.keys(userProfile).forEach((key) => {
      if (userProfile[key]) {
        settings[key] = userProfile[key];
      }
    });
  }
  settings.__raw = { ...setting };

  /**
   * @example
   *
   * export interface SettingsContextInterface {
   *   utils: {
   *     displayDSTFromUTC: (dateType: import('dayjs').ConfigType, format?: string) => string;
   *     getUTCFromDST: (dateType: import('dayjs').ConfigType) => string;
   *   };
   * }
   * export const SettingsContext = React.createContext<SettingsContextInterface>({});
   * const settings = useContext(SettingsContext);
   *
   * settings.utils.displayDSTFromUTC(new Date()) // 2021-12-21 02:55:49 AM
   */
  settings.utils = {
    getDstDateTimeFromUtc: (utcDateTimeOrStr) => getDstDateTimeFromUtc(utcDateTimeOrStr, settings),
    displayDSTFromUTC: (utcDateTimeOrStr, dateFormat = '') =>
      displayDSTFromUTC(utcDateTimeOrStr, dateFormat, settings),
    displayDateFromUTC: (utcDateTimeOrStr, keepLocalTime?: boolean) =>
      displayDateFromUTC(utcDateTimeOrStr, settings, keepLocalTime),
    getUtcDateTimeFromDst: (dstDateTimeOrStr, dateFormat = '') =>
      getUtcDateTimeFromDst(dstDateTimeOrStr, dateFormat, settings),
    getUTCFromDST: (dstDateTimeOrStr, dateFormat = '', utcFormat = '') =>
      getUTCFromDST(dstDateTimeOrStr, dateFormat, settings, utcFormat),
  };
  return settings;
};

export function renderRouteConfigV3(
  routes,
  contextPath: string,
  config: ConfigJson,
  settings: Settings,
  user: UserRef,
) {
  // Resolve route config object in React Router v3.
  const renderRoute = (item, routeContextPath) => {
    let newContextPath;
    if (/^\//.test(item.path)) {
      newContextPath = item.path;
    } else {
      newContextPath = `${routeContextPath}/${item.path}`;
    }
    newContextPath = newContextPath.replace(/\/+/g, '/');
    if (item.component && item.childRoutes) {
      const childRoutes = renderRouteConfigV3(
        item.childRoutes,
        newContextPath,
        config,
        settings,
        user,
      );
      children.push(
        <Route
          key={newContextPath}
          render={(props) => (
            <item.component
              {...props}
              config={config}
              settings={settings}
              user={user}
              eventBus={eventBus}
            >
              {childRoutes}
            </item.component>
          )}
          path={newContextPath}
        />,
      );
    } else if (item.component) {
      children.push(
        <Route
          key={newContextPath}
          component={(props) => (
            <item.component
              {...props}
              config={config}
              settings={settings}
              user={user}
              eventBus={eventBus}
            />
          )}
          path={newContextPath}
          exact
        />,
      );
    } else if (item.childRoutes) {
      item.childRoutes.forEach((r) => renderRoute(r, newContextPath));
    }
  };
  routes.forEach((item) => renderRoute(item, contextPath));
  // Use Switch so that only the first matched route is rendered.
  return children;
}

export const notEmptyStr = (str: string) => str && str !== '';

export const listenToEventBus = ({
  rawSettings,
  state,
  setState,
  history,
}: {
  rawSettings: React.MutableRefObject<Settings>;
  state: AppState;
  setState: SetAppState;
  history: History;
}) => {
  eventBus.on('configuration-updated', (message) => {
    console.log('message', message);
    if (notEmptyStr(message.key)) {
      rawSettings.current[message.key] = message.data;
      const settings = mergeSettings(rawSettings.current);
      if (settings?.profileTimeZone) {
        console.log('set-message');
        settings.timeZone = settings.profileTimeZone;
      } else {
        settings.timeZone = 'Etc/UTC';
      }
      setState({
        ...state,
        settings,
        initializing: false,
        user:
          message.key === 'userProfile'
            ? JSON.parse(JSON.stringify(message.data))
            : {
                ...state.user,
              },
      });
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-shadow
  eventBus.on('navigate-page', (path, newState) => {
    if (path) {
      history.push(path, newState);
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-shadow
  eventBus.on('set-browser-title', (setting) => {
    if (setting?.title && document) {
      document.title = setting.title;
    }
  });
};
