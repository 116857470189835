import axios from 'axios';
import eureka from 'eureka';
import { ConfigJson, UserInfo, CSRFTokenInfo } from 'src/types';

const { axiosCreate } = eureka.HttpWrapper;
const axiosWrapper = axiosCreate();
const axiosWithCSRF = axiosWrapper.instance;

export const fetchFunctions = {
  fetchConfig() {
    return axios.get<ConfigJson>('/config.json');
  },
  fetchAuth() {
    return axios.get<UserInfo>('/auth/user');
  },
  fetchCsrf() {
    return axios.request<CSRFTokenInfo>({
      headers: {
        mode: 'cors',
        cache: 'no-cache',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
      },
      url: `/auth/csrf-token`,
      method: 'POST',
      withCredentials: true,
    });
  },
  // fetchUserCreate() {
  //   return axiosWithCSRF.get('/api/rgm-user-management/business/v1/users/me/create');
  // },
  fetchSettings() {
    return Promise.all([
      axiosWithCSRF.get('/api/rgm-user-management/business/v1/users/me'),
      axiosWithCSRF.get('/api/rgm-user-management/business/v1/users/roles'),
      new Promise((resolve, reject) => { // use cases when feature-toggle-proxy not enabled, switch to mock data
        axiosWithCSRF.get(`/api/feature-toggle-wrapper/business/v1/feature-toggles`).then((result) => {
          resolve(result);
        }, () => {
          resolve({
            data: {
              resultList: [
                { feature: 'test', isEnable: false, isDelete: true },
              ]
            }
          });
        })
      }),
      axiosWithCSRF.get("/api/rgm-central-config/business/v1/rgp/odata/FundGlobalToggles('FGT_TOGGLE')"),
    ]);
  }
};

export default () => {
  return fetchFunctions;
};
