/* eslint-disable no-empty */
import React, { useCallback } from 'react';
import { Dispatch } from 'redux';
import eureka from 'eureka';
import { Button } from '@ui5/webcomponents-react';
import { NotificationListItem } from '@ui5/webcomponents-react';
import { Priority } from '@ui5/webcomponents-react';
import { createUseStyles } from 'react-jss';
import history from '../../common/history';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { Settings, Notification } from 'src/types';
import {
  NOTIFICATION_ACTION_TYPE,
  NOTIFICATION_BO_TYPE,
  NOTIFICATION_MESSAGE_TYPE,
} from 'src/common/constants';
import { NotificationActionType } from 'src/types/store';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
import { eventBus } from './UserProfile/eureka';

type Props = {
  key: string;
  notification: Notification;
  settings: Settings;
  onCloseFunction: onCloseFunction;
  closeNotificationPopover: () => void;
};

type onCloseFunction = (notification: Notification) => void;

/**
Range	Key	Sample Output
0 to 44 seconds	s	a few seconds ago
unset	ss	44 seconds ago
45 to 89 seconds	m	a minute ago
90 seconds to 44 minutes	mm	2 minutes ago ... 44 minutes ago
45 to 89 minutes	h	an hour ago
90 minutes to 21 hours	hh	2 hours ago ... 21 hours ago
22 to 35 hours	d	a day ago
36 hours to 25 days	dd	2 days ago ... 25 days ago
26 to 45 days	M	a month ago
45 to 319 days	MM	2 months ago ... 10 months ago
320 to 547 days (1.5 years)	y	a year ago
548 days+	yy	2 years ago ... 20 years ago
 */

const { useTranslation, i18n } = eureka.I18nProvider;
const buttonStyle = {
  margin: '3px',
};
const Second = 1000;
const Minute = 60 * Second;
const Hour = 60 * Minute;
const Day = 24 * Hour;
const Year = 365 * Day;

const useStyles = createUseStyles({
  notificationStyle: {
    width: 'calc(100% - 10px)',
    marginTop: '4px',
    marginBottom: '4px',
    borderRadius: '0.25rem',
    boxShadow: 'var(--sapContent_Shadow0)',
    '& > div > div': {
      boxShadow: 'none',
    },
  },
  negative: {
    '--sapSuccessBorderColor': ThemingParameters.sapNegativeElementColor,
  },
  warning: {
    '--sapSuccessBorderColor': ThemingParameters.sapCriticalElementColor,
  },
  wrapper: {
    display: 'flex',
  },
  contentWrapper: {
    width: '301px',
    marginBottom: '-20px',
  },
  content: {
    marginTop: '20px',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    color: 'var(--sapContent_LabelColor)',
  },
  footWrapper: {
    padding: '10px 0',
  },
  footer: {
    textAlign: 'right',
    marginRight: '-48px',
  },
  indicator: {
    display: 'inline-block',
    margin: '4px 0',
    borderRadius: '0.25rem 0 0 0.25rem',
    width: '0.375rem',
    minWidth: '0.375rem',
    backgroundColor: 'var(--sapSuccessBorderColor)',
  },
  indicatorError: {
    display: 'inline-block',
    margin: '4px 0',
    borderRadius: '0.25rem 0 0 0.25rem',
    width: '0.375rem',
    minWidth: '0.375rem',
    backgroundColor: 'var(--sapErrorBorderColor)',
  },
});

export const getMessageData = (notification: Notification) => {
  let messageData: any = {};
  try {
    messageData = JSON.parse(notification?.data?.message);
  } catch (e) {
    console.error(e);
  }
  return messageData;
};

export const getDisplayMessage = (message: string) => {
  let ret: any = {};
  try {
    ret = JSON.parse(message);
    return ret?.content ? ret.content : message; // if no content is found, return original message string
  } catch (e) {
    console.error(e);
  }
  return ret;
};

export const getNotificationPriority = (
  changeSeverity: string,
  changeResult: string,
  isSuccess: boolean,
) => {
  let notificationPriority = Priority.None;
  if (changeResult) {
    notificationPriority = isSuccess ? Priority.Low : Priority.High;
  } else {
    switch (changeSeverity) {
      case 'Critical':
        notificationPriority = Priority.High;
        break;
      case 'Warning':
        notificationPriority = Priority.Medium;
        break;
      case 'Informative':
        notificationPriority = Priority.Low;
        break;
      default:
        break;
    }
  }
  return notificationPriority;
};

export const getIndicatorColor = (
  classes: any,
  notificationPriority: string,
  changeResult: string,
  isSuccess: boolean,
) => {
  let indicatorColor = '';
  if (changeResult) {
    indicatorColor = isSuccess ? '' : classes.negative;
  } else {
    switch (notificationPriority) {
      case Priority.Low:
        indicatorColor = '';
        break;
      case Priority.Medium:
        indicatorColor = classes.warning;
        break;
      case Priority.High:
        indicatorColor = classes.negative;
        break;
      default:
        break;
    }
  }

  return indicatorColor;
};

export const buildResult = (
  notification: Notification,
  t: any,
  closeNotificationPopover: () => void,
) => {
  let additionalCallback: any = null;
  const isSuccess = ['Done', 'DONE', 'SUCCESS'].includes(notification?.data?.changeResult);
  const notificationPriority = getNotificationPriority(
    notification?.data?.changeSeverity,
    notification?.data?.changeResult,
    isSuccess,
  );
  const notificationKey = `${notification.data.businessObject}_${notification.data.changeAction}`;
  const titleKey = `${notificationKey}_${isSuccess ? 'SUCCESS' : 'FAILED'}`;
  const title = notification?.data?.title
    ? t('notication title', notification?.data?.title, { type: 'tit', desc: '' })
    : t([titleKey, 'Notification_Other']);

  const messageData = getMessageData(notification);
  const messageKey = `${notificationKey}_${isSuccess ? 'SUCCESS' : 'FAILED'}_MESSAGE`;
  const message = i18n.exists(messageKey)
    ? t(messageKey, { ...messageData })
    : getDisplayMessage(notification?.data?.message);

  let viewButtonUrl = '',
    error = '';
  switch (notification.data.businessObject) {
    case NOTIFICATION_MESSAGE_TYPE.INTEGRATION.name:
      if (
        NOTIFICATION_MESSAGE_TYPE.INTEGRATION.promotionChangeActions.includes(
          notification.data.changeAction,
        )
      ) {
        viewButtonUrl = `/promotion-planning/account-plans/${messageData.accountPlanId}/view/${messageData.promotionUuid}`;
        error = messageData.error;
      }
      break;
    case NOTIFICATION_MESSAGE_TYPE.ACCOUNT_PLAN.name:
      if (
        NOTIFICATION_MESSAGE_TYPE.ACCOUNT_PLAN.accountPlanChangeActions.includes(
          notification.data.changeAction,
        )
      ) {
        viewButtonUrl = `/promotion-planning/account-plans/${notification.data.businessObjectUUID}?tab=sell-in-volume`;
        error = messageData.error || '';
      }
      break;
    case NOTIFICATION_MESSAGE_TYPE.JOULE.name:
      if (NOTIFICATION_MESSAGE_TYPE.JOULE.jouleActions.includes(notification.data.changeAction)) {
        viewButtonUrl = `/promotion-planning/account-plan-dashboard`;
        additionalCallback = () => {
          eventBus.emit('JouleAction', notification.data.changeAction, notification.data);
          closeNotificationPopover();
        };
        error = messageData.error || '';
      }
      break;
    default:
      break;
  }

  return {
    title,
    message,
    additionalCallback,
    notificationPriority,
    viewButtonUrl,
    isSuccess,
    error,
  };
};

const CustomNotification: React.FC<Props> = ({
  notification,
  settings,
  onCloseFunction,
  closeNotificationPopover,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const fromNow = dayjs(notification.receivedTime).fromNow();
  const datetime = (
    <div style={{ marginTop: '5px', color: 'var(--sapContent_LabelColor)' }}>{fromNow}</div>
  );

  const boActionMap = {
    ResponsibilityArea: {
      NAVIGATION: `/responsibility-areas/${notification?.data?.businessObjectUUID}`,
    },
    AccountPlan: {
      NAVIGATION: `/promotion-planning/account-plans/${notification?.data?.businessObjectUUID}`,
    },
  };

  const {
    isSuccess,
    viewButtonUrl,
    additionalCallback,
    message,
    title,
    error,
    notificationPriority,
  } = buildResult(notification, t, closeNotificationPopover);

  const onClose = useCallback(() => {
    onCloseFunction(notification);
  }, [notification, onCloseFunction]);

  const onActionClick = useCallback(
    (evt) => {
      const { actionrefid: actionRefId } = evt.target.dataset;
      const action = notification.data.actions.find((x) => x.actionRefId === actionRefId);
      const boActionItem = boActionMap[notification?.data?.businessObject];
      if (boActionItem && action?.actionName === NOTIFICATION_ACTION_TYPE.NAVIGATION) {
        history.push(boActionItem.NAVIGATION);
      }
    },
    [notification],
  );

  try {
    // This code fails in UT, so let's catch it.
    dayjs.updateLocale(dayjs.locale(), {
      relativeTime: {
        future: '%s',
        past: '%s',
        s: i18next.t('aFewSecondsAgo', 'a few seconds ago', { type: 'act', desc: '' }),
        m: i18next.t('aMinuteAgo', 'a minute ago', { type: 'act', desc: '' }),
        mm: i18next.t('minutesAgo', '%d minutes ago', { type: 'act', desc: '' }),
        h: i18next.t('anHourAgo', 'an hour ago', { type: 'act', desc: '' }),
        hh: i18next.t('hoursAgo', '%d hours ago', { type: 'act', desc: '' }),
        d: i18next.t('aDayAgo', 'a day ago', { type: 'act', desc: '' }),
        dd: i18next.t('daysAgo', '%d days ago', { type: 'act', desc: '' }),
        M: i18next.t('aMonthAgo', 'a month ago', { type: 'act', desc: '' }),
        MM: i18next.t('monthsAgo', '%d months ago', { type: 'act', desc: '' }),
        y: i18next.t('aYearAgo', 'a year ago', { type: 'act', desc: '' }),
        yy: i18next.t('yearsAgo', '%d years ago', { type: 'act', desc: '' }),
      },
    });
  } catch (e) {}

  return (
    <div className={classes.wrapper}>
      <div
        className={`${classes.indicator} ${getIndicatorColor(
          classes,
          notificationPriority,
          notification?.data?.changeResult,
          isSuccess,
        )}`}
      />
      <NotificationListItem
        className={classes.notificationStyle}
        titleText={title}
        priority={notificationPriority}
        showClose
        read={false}
        wrappingType="Normal"
        onClose={onClose}
      >
        <div className={classes.contentWrapper}>
          <div className={classes.content}>{message}</div>
          {!!error && !isSuccess && <div className={classes.content}>{error}</div>}
          <div className={classes.footWrapper}>
            {datetime}
            <div className={classes.footer}>
              {!!viewButtonUrl && (
                <Button
                  style={buttonStyle}
                  design="Default"
                  onClick={() => {
                    history.push(viewButtonUrl);
                    additionalCallback && additionalCallback();
                  }}
                  data-testid="notification-view-button"
                >
                  {/* {t('Notification_ViewBtn')} */}
                  {'Show'}
                </Button>
              )}
              {notification?.data?.actions?.map((action: NotificationActionType, index: number) => {
                return (
                  <Button
                    style={buttonStyle}
                    design="Default"
                    key={index}
                    data-actionRefId={action.actionRefId}
                    onClick={onActionClick}
                    data-testid="notification-action-button"
                  >
                    {action.displayName}
                  </Button>
                );
              })}
            </div>
          </div>
        </div>
      </NotificationListItem>
    </div>
  );
};

export default CustomNotification;
