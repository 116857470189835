/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';
import HeartBeatWebSocket from './HeartBeatWebSocket';
import { COMMON_GET_NOTIFICATIONS_SUCCESS, COMMON_GET_NOTIFICATIONS_FAILED } from './constants';
import { AxiosInstance } from 'axios';
import { Notification } from 'src/types';
import dayjs from 'dayjs';

const { eventBus } = eurekaMgrs;
const { axiosCreate } = eureka.HttpWrapper;
const { getConfig } = eurekaMgrs.ConfigManager;

const injectInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    const { tenantId, id } = getConfig('user') || {};
    config.headers['X-Tenant-ID'] = tenantId;
    config.headers['X-User-ID'] = id;
    return config;
  });
  return axiosInstance;
};

const notificationAxios = injectInterceptor(
  axiosCreate({
    baseURL: '/api/rgp-notification-push/v1/messages',
  }).instance,
);
let __dispatch;
let __websocket;
let isMessageEnabled = false;

function fetchNotifications(args = {}, search = '', sortBy = '', pageSize = 10, pageCount = 1) {
  let promise: any = null;
  const enableJoule = eurekaMgrs.ConfigManager.getFeatureToggle(
    'rgp.9545.joule.rgm.integration.demo',
  );
  const DateFormat = 'YYYY-MM-DD HH:mm:ss';
  if (enableJoule) {
    const jouleMockData = [{
        id: 'joule-001',
        messageId: 'joule-001',
        data: {
          title: 'Joule',
          message: JSON.stringify({
            "accountPlanId": "account plan id",
            "promotionUuid": "promotion uuid",
            "promotionName": "A new promotion",
            "promotionId": 'PROMO-2233',
            "content": "A new Product Category, New Seasonal Edition is added to your RA. It will be effective from Q4. You have 2 customers with active account plan can leverage this new product category."
          }),
          changeSeverity: 'High',
          changeResult: 'SUCCESS', // Done, DONE, SUCCESS, others mean failed
          businessObject: 'JOULE',
          businessObjectUUID: '--',
          changeAction: 'JOULE_PRODUCT_GROUP',
        },
        changeAction: {},
        receivedTime: dayjs().subtract(20, 'second').format(DateFormat)
      }, {
        id: 'joule-004',
        messageId: 'joule-004',
        data: {
          title: 'Joule',
          message: JSON.stringify({
            "accountPlanId": "",
            "promotionUuid": "",
            "promotionName": "",
            "promotionId": '',
            "content": "A new pricing for Product Latte Perfect is going to be effective in Q4, It impacts your account plan."
          }),
          changeSeverity: 'High',
          changeResult: 'SUCCESS', // Done, DONE, SUCCESS, others mean failed
          businessObject: 'JOULE',
          businessObjectUUID: '--',
          changeAction: 'JOULE_PRICE_CHANGE',
        },
        changeAction: {},
        receivedTime: dayjs().subtract(50, 'second').format(DateFormat)
      }, {
        id: 'joule-002',
        messageId: 'joule-002',
        data: {
          title: 'System',
          message: JSON.stringify({
            "accountPlanId": "account plan id",
            "promotionUuid": "promotion uuid",
            "promotionName": "A new promotion",
            "promotionId": 'PROMO-2233',
            "content": "A new Product Group Flat White Coffee is added to your RA. It will be effective from Q4. You have 3 customers with active account plan can leverage this new product group."
          }),
          changeSeverity: 'Low',
          changeResult: 'SUCCESS', // Done, DONE, SUCCESS, others mean failed
          businessObject: 'INTEGRATION',
          businessObjectUUID: '--',
          changeAction: 'P_ACTION_RELEASE',
        },
        changeAction: {},
        receivedTime: dayjs().subtract(30, 'minute').format(DateFormat)
      }, {
        id: 'joule-003',
        messageId: 'joule-003',
        data: {
          title: 'System',
          message: JSON.stringify({
            "accountPlanId": "account plan id",
            "promotionUuid": "promotion uuid",
            "promotionName": "A new promotion",
            "promotionId": 'PROMO-2233',
            "content": "Budge has been set for the customer SAPBucks in year 2025"
          }),
          changeSeverity: 'Medium',
          changeResult: 'SUCCESS', // Done, DONE, SUCCESS, others mean failed
          businessObject: 'ACCOUNT_PLAN',
          businessObjectUUID: '--',
          changeAction: 'P_ACTION_RELEASE',
        },
        changeAction: {},
        receivedTime: dayjs().subtract(1.1, 'h').format(DateFormat)
      }]
    promise = Promise.resolve(jouleMockData)
    __dispatch && __dispatch({
      type: COMMON_GET_NOTIFICATIONS_SUCCESS,
      data: jouleMockData,
    });
  } else {
    promise = new Promise((resolve, reject) => {
      const doRequest = notificationAxios({
        url: '',
        method: 'get',
        withCredentials: true,
      });
      doRequest.then(
        (res) => {
          __dispatch && __dispatch({
            type: COMMON_GET_NOTIFICATIONS_SUCCESS,
            data: (res && res.data) || [],
          });
          resolve(res);
        },
        (err) => {
          __dispatch && __dispatch({
            type: COMMON_GET_NOTIFICATIONS_FAILED,
            data: err,
          });
          reject(err);
        },
      );
    });
  }
  return promise;
}

function onMessage(evt: { data: any }) {
  if (__dispatch && !evt.data.startsWith('HeartBeat')) {
    fetchNotifications();
  }
  // refresh deduction table and status
  if (evt?.data) {
    if (
      evt.data.businessObject &&
      evt.data.businessObject === 'Deduction' &&
      evt.data.changeAction === 'AutoFill'
    ) {
      eventBus.emit('DeductionAutoFilled', '', evt.data);
    }
    // tell visit-execution pload is finished
    if (
      evt.data.businessObject &&
      evt.data.businessObject === 'VISIT_PHOTO' &&
      evt.data.changeAction === 'ADD_ATTACHMENT'
    ) {
      eventBus.emit('VisitPhotoUploaded', '', evt.data);
    }
  }
}

export function refreshNewMessages() {
  return setInterval(() => {
    if (isMessageEnabled) {
      fetchNotifications();
    }
  }, 60 * 1000);
}

export function closeWebsocket() {
  return (disptach) => {
    __websocket && __websocket._ws && __websocket._ws.close();
  };
}

export function startWebsocket() {
  return (dispatch) => {
    if (__dispatch) {
      return;
    }
    __dispatch = dispatch;
    // if (!__websocket) {
    //   const protocol = window.location.protocol.startsWith('https') ? 'wss' : 'ws';
    //   const url = `${protocol}://${window.location.host}/api/rgp-notification-push/`;
    //   __websocket = new HeartBeatWebSocket(url, onMessage).start();
    // }
    fetchNotifications().then(
      () => {
        isMessageEnabled = true;
      },
      () => {
        isMessageEnabled = false;
        console.log('Message channel is supported, so will not start web socket');
      },
    );

    // listen close websocket of eventbus
    eventBus.on('close-websocket', (evtBody) => {
      console.log(evtBody);
      console.log('web socket close of eventbus');
      closeWebsocket();
    });
  };
}

export function dismissNotification(notification: Notification) {
  return (disptach) =>
    new Promise((resolve, reject) => {
      const doRequest = notificationAxios({
        url: `/${notification.messageId}`,
        method: 'POST',
        withCredentials: true,
      });
      doRequest
        .then(() => fetchNotifications())
        .then((res) => resolve(res))
        .catch((err) => {
          reject(err);
        });
    });
}

export function dismissAllNotifications() {
  return (disptach) =>
    new Promise((resolve, reject) => {
      const doRequest = notificationAxios({
        url: `/ack-all`,
        method: 'post',
        withCredentials: true,
      });
      doRequest
        .then(() => fetchNotifications())
        .then((res) => resolve(res))
        .catch((err) => {
          reject(err);
        });
    });
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.data,
      };
    case COMMON_GET_NOTIFICATIONS_FAILED:
      return {
        ...state,
        notifications: [],
      };
    default:
      return state;
  }
}
