// @ts-ignore
__webpack_public_path__ = window.__shell_webpack_public_path__ || '/';

const consoleError = console.error;
console.error = function (...args) {
  if (
    args?.length > 0 &&
    args[0]?.startsWith && 
    args[0]?.startsWith(
      'Warning: Detected multiple renderers concurrently rendering the same context provider',
    )
  ) {
    return;
  }
  return consoleError.apply(console, args);
};

// @ts-ignore
export default {};
