import React, { useEffect } from 'react';
import { Select, Option, Label, Text } from '@ui5/webcomponents-react';
import { SegmentedButton, SegmentedButtonItem } from '@ui5/webcomponents-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import * as actions from '../redux/actions';
import { fetchTimeZone } from '../redux/actions';
import {
  DateFormatOptions,
  DateFormatTexts,
  TimeFormatOptions,
  TimeFormatTexts,
  TimeFormatTextsDefualts,
} from './options';
import { useTranslation } from './eureka';
import { UserProfileState } from 'src/types';

dayjs.extend(utc);
dayjs.extend(timezone);

const formItemStyle: React.CSSProperties = {
  width: '100%',
  margin: '15px auto',
  display: 'flex',
  flexDirection: 'column',
};

const labelStyle: React.CSSProperties = {
  width: '100%',
  marginBottom: '4px',
};

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    data: UserProfileState;
    languages: languages[];
    onDataPropChange: (value: string, propName: string) => void;
  };

interface languages {
  language: string;
  languageCode: string;
  displayLanguage: {
    nativeLanguage: string;
    englishLanguage: string;
  };
  displayLanguageCode: string;
}

const LanguageAndRegion: React.FC<Props> = ({
  data,
  languages,
  actions,
  common,
  onDataPropChange,
}) => {
  const { t } = useTranslation('shell');
  const { fetchTimeZone } = actions;
  const { timezone } = common;

  useEffect(() => {
    if (!timezone) {
      fetchTimeZone();
    }
  }, []);

  return (
    <div style={{ padding: '1rem' }}>
      <div style={{ ...formItemStyle, margin: '0 auto 15px' }}>
        <Label showColon style={labelStyle}>
          {t('UserProfile_Language', 'Language', { type: 'tit', desc: '' })}
        </Label>
        <Select
          onChange={(evt) => {
            const lang = evt?.detail?.selectedOption?.dataset?.id;
            /* istanbul ignore next */
            lang && onDataPropChange(lang, 'language');
          }}
          id="language"
          data-testid="user-profile-select-language"
          style={{ width: '100%' }}
        >
          {languages.map((language: languages) => {
            return (
              <Option
                key={language.languageCode}
                data-id={language.languageCode}
                value={language.languageCode}
                selected={language.languageCode === data.language}
              >
                {`${language.displayLanguage.nativeLanguage} (
                  ${language.language}
                )`}
              </Option>
            );
          })}
        </Select>
      </div>
      <div style={formItemStyle}>
        <Label showColon style={labelStyle}>
          {t('UserProfile_TimeZone', 'Time Zone', { type: 'tit', desc: '' })}
        </Label>
        <Select
          data-testid="user-profile-time-zone"
          onChange={(evt) => {
            const timezone = evt?.detail?.selectedOption?.dataset?.id;
            /* istanbul ignore next */
            timezone && onDataPropChange(timezone, 'profileTimeZone');
          }}
          id="timeZone"
          style={{ width: '288px' }}
        >
          {timezone?.map((timeZone, index) => {
            return (
              <Option
                key={timeZone.timezone}
                data-id={timeZone.timezone}
                value={timeZone.timezone}
                selected={timeZone.timezone === data.profileTimeZone}
              >
                {timeZone.displayName}
              </Option>
            );
          })}
        </Select>
      </div>
      <div style={formItemStyle}>
        <Label showColon style={labelStyle}>
          {t('UserProfile_DateFormat', 'Date Format', { type: 'tit', desc: '' })}
        </Label>
        <Select
          data-testid="user-profile-date-format"
          // value={data.dateFormat}
          onChange={(evt) => {
            const dateFormat = evt?.detail?.selectedOption?.dataset?.id;
            /* istanbul ignore next */
            dateFormat && onDataPropChange(dateFormat, 'dateFormat');
          }}
          id="dateFormat"
          style={{ width: '100%' }}
        >
          {DateFormatOptions.map((dateFormat, index) => {
            return (
              <Option
                key={dateFormat}
                data-id={dateFormat}
                value={dateFormat}
                selected={dateFormat === data.dateFormat}
              >
                {DateFormatTexts[index]}
              </Option>
            );
          })}
        </Select>
      </div>
      <div style={formItemStyle}>
        <Label showColon style={labelStyle}>
          {t('UserProfile_TimeFormat', 'Time Format', { type: 'tit', desc: '' })}
        </Label>
        <SegmentedButton
          data-testid="user-profile-time-format"
          onSelectionChange={(evt) => {
            onDataPropChange(evt?.detail?.selectedItem?.id, 'timeFormat');
          }}
        >
          {TimeFormatOptions.map((timeFormat, index) => (
            <SegmentedButtonItem
              key={timeFormat}
              id={timeFormat}
              pressed={timeFormat === data.timeFormat}
              data-testid={`time-format-${timeFormat}`}
            >
              {t(TimeFormatTexts[index], TimeFormatTextsDefualts[index], {
                type: 'but',
                desc: '',
              })}
            </SegmentedButtonItem>
          ))}
        </SegmentedButton>
      </div>
      <div style={formItemStyle}>
        <Label showColon style={labelStyle}>
          {t('UserProfile_DateFormat_Text', 'Date', {
            type: 'tit',
            desc: 'Label text for date format sample',
          })}
        </Label>
        <Text>
          {data.profileTimeZone && dayjs().utc().tz(data.profileTimeZone).format(data.dateFormat)}
        </Text>
      </div>
      <div style={formItemStyle}>
        <Label showColon style={labelStyle}>
          {t('UserProfile_TimeFormat_Text', 'Time', {
            type: 'tit',
            desc: 'Label text for time format sample',
          })}
        </Label>
        {
          <Text>
            {data.profileTimeZone &&
              dayjs()
                .utc()
                .tz(data.profileTimeZone)
                .format(`${data.timeFormat === 'TWENTYFOUR_HOUR' ? 'HH:mm' : 'hh:mm A'}`)}
          </Text>
        }
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, fetchTimeZone }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageAndRegion);
